<template>
    <div class="PlanStepSucceed">
        <i class="iconfont">&#xe730;</i>
        <span>恭喜您！完成推广！</span>
        <div class="PlanStepSucceedBtn">
            <el-button @click="toBack">返回编辑单元</el-button>
            <el-button class="ckBtn" @click="toNext">查看推广详情</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PlanStepSucceed",
        data(){
            return {

            }
        },
        methods: {
            toBack(){
                this.$http.axiosGet(this.$api.storeDrainage, res=>{
                    if(res.code === 200){
                        // console.log(24,res)
                        if(res.data.data.length){
                            let id = res.data.data[0].id;
                            this.$router.push({
                                path:'/student/operationpromotion/boothadvertisingthree',
                                query:{
                                    editId:id
                                }
                            })
                        } else {
                            this.$router.go(-1)
                        }
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            toNext(){
                this.$router.push({
                    path:'/student/operationpromotion/promotionmanagement'
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    /deep/.el-button--default:hover{
        border-color: #FD4446;
        color: #FD4446;
        background: #ffffff;
    }
    .PlanStepSucceed{
        height: calc(100vh - 400px);
        padding-top: 104px;
        padding-bottom: 130px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .iconfont{
            font-size: 60px;
            color: #20D08C;
        }
        span{
            color: #333333;
            font-size: 24px;
            font-weight: 500;
            margin-top: 20px;
        }
        .PlanStepSucceedBtn{
            margin-top: 40px;
        }
        .ckBtn{
            background-color: #FD4446;
            border-color: #FD4446;
            color: #ffffff;
        }
    }
</style>